<template>
    <div class="wellness page pb-5">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <router-link class="go-back" :to="{name: 'services'}">
                        <img src="@/assets/images/icons/ic-arrow-right-black-big.svg" class="go-back-img" />
                        {{tr('fitness_go_back')}}
                    </router-link>
                </b-col>
            </b-row>
            <b-row class="pb-5 mb-5">
                <b-col cols="12" md="6">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"><span class="gold-lined-text animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">Wellness</span></h2> 
                    <div class="wellness__text">
                        <p class="page-lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">{{tr('wellness_text_1')}}</p>
                        <p class="d-none d-md-block animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-3')">{{tr('wellness_text_2')}} </p>                        
                    </div>
                </b-col>
                <b-col cols="12" md="6">
                    <img :src="baseUrl + 'pictures/wellness/gallery/wellness-04.jpg'" alt="Luminor Apartments" class="w-100 animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')" />
                    <!-- mobile text -->
                    <p class="d-md-none">{{tr('wellness_text_2')}}  </p>                   
                </b-col>
            </b-row>                                    
        </b-container>
        <!--<TakeLookInside  :flatslides="wellnessImgs" :flatslidesBig="[]" :showTitle="false" />-->
    </div>
</template>

<script>
//import TakeLookInside from '@/components/pages/TakeLookInside.vue'

export default {
    data(){
        return{
            wellnessImgs: [
                "/pictures/wellness/gallery/wellness-01.jpg",
                "/pictures/wellness/gallery/wellness-02.jpg",
                "/pictures/wellness/gallery/wellness-03.jpg",
                "/pictures/wellness/gallery/wellness-04.jpg"         
            ]
        }
    },
    components:{
        //TakeLookInside
    }
}
</script>